import styled, {css}  from "styled-components";

export const HeaderOne = styled.h1`
  font-size: 64px;
`;
export const HeaderTwo = styled.h2`
  font-size: 48px;
`;
export const HeaderThree = styled.h3`
  font-size: 24px;
`;
export const HeaderFour = styled.h3`
  font-size: 18px;
`;

/* size variants */
export const large = css`
  font-size: 24px;
`;
export const medium = css`
  font-size: 18px;
`;
export const normal = css`
  font-size: 14px;
`;
export const small = css`
  font-size: 12px;
`;

/* text variant */
export const Text = styled.span`
  font-family: Roboto Mono;
  font-style: normal;
  line-height: 155.14%;
  text-transform: none;
  color: ${props => props.color || "#000000"};
  ${(props) => (props.fontSize === "large" ? large : props.fontSize === "medium" ? medium : props.fontSize === "small" ? small :  normal)};
  font-weight: ${(props) => (props.bold ? "bold;" : undefined)};

  `;

export const Caption = styled(Text)`
font-size: 18px;
letter-spacing: 0.001em;
text-transform: uppercase;
`;

