import React from "react";
import styled from "styled-components";
import { Caption } from "../typography";
import { ReactComponent as Logo01 } from "../../../assets/logos/Logo01.svg";
import { ReactComponent as Logo02 } from "../../../assets/logos/Logo02.svg";
import { ReactComponent as Logo03 } from "../../../assets/logos/Logo03.svg";
import { ReactComponent as Logo04 } from "../../../assets/logos/Logo04.svg";
import { ReactComponent as Logo05 } from "../../../assets/logos/Logo05.svg";

const Logo = styled(Caption)`
  color: #353142;
  background-color: "black";
  text-align: left;
`;

const LogoComponent = (props) => {
  const variant = props.variant;
  switch (variant) {
    case 1:
      return (
        <Logo01
          style={{ height: "60", width: "100%", marginLeft: "0" }}
        ></Logo01>
      );
    case 2:
      return (
        <Logo02
          style={{ height: "60", width: "100%", marginLeft: "0" }}
        ></Logo02>
      );
    case 3:
      return (
        <Logo03
          style={{ height: "60", width: "100%", marginLeft: "0" }}
        ></Logo03>
      );
    case 4:
      return (
        <Logo04
          style={{ height: "60", width: "100%", marginLeft: "0" }}
        ></Logo04>
      );
      case 5:
        return (
          <Logo05
            style={{ height: "60", width: "100%", marginLeft: "0" }}
          ></Logo05>
        );
    default:
      return <Logo className={props.className}>Logo</Logo>;
  }
};

export default LogoComponent;
