import { AppBar, Button, Toolbar } from "@mui/material";
import * as React from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import WalletButton from "../../atoms/Button/wallet";
import { Text } from "../../atoms/typography";
import LogoComponent from "../../atoms/Logo";

const StyledAppBar = styled(AppBar)``;

const StyledToolBar = styled(Toolbar)`
  background-color: #ffffff;
  && {
    padding: 0px 52px;
  }
  justifycontent: "space-between";
  height: 100px;
`;

const headersData = [
  {
    label: "EXPLORE",
    href: "/explore",
  },
  {
    label: "MY PAGE",
    href: "/mypage",
  },
  {
    label: "CREATE PIXSHOW",
    href: "/create",
  },
  {
    label: "Playground",
    href: "/mock",
  },
];

const StyledButton = styled(Button)`
  margin: auto 16px auto;
`;

const getMenuButtons = () => {
  return headersData.map(({ label, href }) => {
    return (
      <StyledButton
        {...{
          key: label,
          to: href,
          component: RouterLink,
        }}
      >
        <Text fontSize="medium" color="#B1ADBD">
          {label}
        </Text>
      </StyledButton>
    );
  });
};

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

const StyledWallet = styled.div`
  margin-left: 30px;
`;
const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

let comingsoon = false;

export default function Header(props) {
  const logoVariant = props.logoVariant || 5;
  return (
    <header>
      <StyledAppBar position="sticky">
        <StyledToolBar>
          <div>
            <StyledLink to="/">
              <LogoComponent variant={logoVariant}></LogoComponent>
            </StyledLink>
          </div>
          {comingsoon && <RightAlign>{getMenuButtons()}</RightAlign>}
          {comingsoon && (
            <StyledWallet>
              <WalletButton></WalletButton>
            </StyledWallet>
          )}
        </StyledToolBar>
      </StyledAppBar>
    </header>
  );
}
