import React from "react";
// import Navbar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer";
import Home from "./pages/Home";
// import PlayGround from "./pages/PlayGround";
// import CreatePage from "./pages/CreatePage/CreatePage";
// import Explore from "./pages/Explore";
// import MyPage from "./pages/MyPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
// import TestHook from "./pages/TestHook";
// import System from "./system/System";
import GlobalStyle from "./utils/Global";
import Header from "./components/organism/header";
import styled from "styled-components";
// import EditSpacePage from "./pages/CreatePage/EditSpacePage";
import { StyledEngineProvider } from "@mui/material/styles";

function getLibrary(provider) {
  return new Web3(provider);
}

const FooterSection = styled.div`
  padding: 1rem;
  height: 140px;
  background-color: #353142;
  bottom: 0;
  left: 0;
  width: 100%;
`;

function App() {
  return (
    <StyledEngineProvider injectFirst>
      {/* <StylesProvider jss={jss}> */}
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <Header></Header>
          <GlobalStyle />
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/explore" element={<Explore />} />
              <Route path="/mypage" element={<MyPage />} />
              <Route path="/create" element={<CreatePage />} />
              <Route path="/createSpace" element={<PlayGround />} />
              <Route path="/mock" element={<PlayGround />} />
              <Route path="/play" element={<TestHook />} />
              <Route path="/system" element={<System />} />
              <Route path="/edit" element={<EditSpacePage />} /> */}
            </Routes>
          </div>
        </Router>
        <FooterSection></FooterSection>
      </Web3ReactProvider>
      {/* </StylesProvider> */}
    </StyledEngineProvider>
  );
}

export default App;
