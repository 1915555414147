import React from "react";
import { HeaderOne } from "../components/atoms/typography";

const Home = (props) => {
  return (
    <div className="Highlight">
      <HeaderOne>Decorative Space for showcase your NFTs</HeaderOne>
      <p>Coming Soon!</p>
    </div>
  );
};

export default Home;
