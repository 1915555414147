import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
.App {
    font-family: "Roboto Mono";
    font-style: normal;
  }
  

  h1,h2,h3,h4 {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: bold;
    line-height: 143.2%;
    color: #000000;
  }

  h1{
    font-size: 80px;
  }
  h2{
    font-size: 60px;
  }
  h3{
    font-size: 30px;
  }
  h4{
    font-size: 24px;
  }

  .smb{
  font-weight: bold;
  line-height: 155.14%;
  }

  .lg-text{
    font-size: 24px;
    line-height: 155.14%;
  }

  .md-text{
    font-size: 20px;
    line-height: 155.14%;
  }

  .text{
    font-size: 16px;
    line-height: 155.14%;
  }

  .sm-text{
    font-size: 12px;
    line-height: 155.14%;
  }

  .caption-text{
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 155.14%;

    letter-spacing: 0.001em;
    text-transform: uppercase;
  }

  .rowC{display:flex; flex-direction:row;}
  .container {
    width: fit-content;
  }

  footer {
    text-align: center;
    margin-top:calc(5% + 60px);
    bottom: 0;
    width: 100%;
    height: 2.5rem;
  }
  
  .Highlight{
    color: black;
    margin: 10% 15% ;
    padding-right: 30%;
  }

`;
export default GlobalStyle;
